.socialMediaLinks {
   margin-top: 15px;
}

.socialMediaLinks a {
   color: gray;
   margin: 15px;
}

.socialMediaLinks a:hover,
.socialMediaLinks a:focus,
.socialMediaLinks a:active  {
   color: #999;
   margin: 15px;
}
